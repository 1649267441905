import React from "react";
import { Col, Row } from "react-bootstrap";
import "./style.css";
import {
  getRandomColor,
  noDataSimple,
  timeFormat,
} from "../../common/GlobalFunctions";
import { Link, useNavigate } from "react-router-dom";
import CustomToolTip from "../CustomToolTip";
import Loading from "../loading";

const TrackingMostHours = (props) => {
  const { loading, dashboardTrackingMostHours, dashboardDateRange } = props;
  const navigate = useNavigate();
  function FindMax(array) {
    return Math.max(...array.map((x) => x.timeTracked));
  }

  function FindPercentange(array, element) {
    return FindMax(dashboardTrackingMostHours) <= 0
      ? 0
      : (element * 100) / FindMax(array);
  }

  const dummySkeletonFunction = () => {
    let dummySkeleton = [];

    for (let i = 0; i < 6; i++) {
      dummySkeleton.push(
        <div
          className="line-hours d-flex justify-content-between align-items-center mb-4"
          key={i}
        >
          <div className="names d-flex align-items-center">
            <span className="d-flex justify-content-center align-items-center p">
              a
            </span>
            <p className="m-0 ms-2">---</p>
          </div>
          <div className="line UnProductive">
            <div className="red progress-bar" style={{ width: "100px" }}></div>
          </div>
          <span className="time">--</span>
        </div>
      );
    }
    return dummySkeleton;
  };

  const handleClickNavigate = () => {
    const paramsObject = {
      timetrack: -1,
      date: dashboardDateRange.dateRange,
      label: dashboardDateRange.label
    };
    const encodedParams = btoa(JSON.stringify(paramsObject));
    navigate(`/hours-track?data=${encodedParams}`);
  };

  return (
    <>
      <Col
        className="box m-0 mb-sm-1 mt-sm-0 mb-md-1 mb-lg-0"
        md={12}
        lg={12}
        xl={6}
      >
        <div
          className={
            "top-projects-box my-3 my-md-1 my-lg-3 mb-0 mt-lg-2 mt-xl-2 box-shadow-light trackinghours overflow-inherit toltip-right" +
            (loading ? " skeleton1 " : "")
          }
        >
          <div className="bg-white p-0  py-3 py-md-2 pb-md-3 px-4 border-radius ">
            {loading ? (
              <div className="text-center loader my-4 pt-1 height-min">
                <Loading />
              </div>
            ) : (
              <>
                {" "}
                <div className="heading">
                    <h3 className="heading heading-font pb-4  align-items-center transform-tooltip team-dashboard-tooltips pt-lg-1">
                    Highest Tracked Hours
                    <CustomToolTip
                      iconColor="black"
                      details="Users who have tracked the most amount of work hours."
                    />
                  </h3>
                </div>
                <Row>
                  <Col sm={12} >
                    {!loading && dashboardTrackingMostHours.length > 0
                      ? dashboardTrackingMostHours.map((s, index) => (
                        <div key={index}>
                          <div className="line-hours d-flex justify-content-between align-items-center mb-4">
                            <div className="names d-flex align-items-center">
                              <span
                                style={{
                                  backgroundColor: getRandomColor(s.name),
                                }}
                                className="d-flex justify-content-center align-items-center p"
                              >
                                {s.name.charAt(0)}
                              </span>
                              <p className="m-0 ms-2">{s.name}</p>
                            </div>
                            <div className="line general ">
                              <div
                                className="green progress-bar"
                                style={{
                                  width: `${FindPercentange(
                                    dashboardTrackingMostHours,
                                    s.timeTracked
                                  )}%`,
                                }}
                              ></div>
                            </div>
                            <span className="time pe-2 d-flex justify-content-end">
                              {timeFormat(s.timeTracked)}
                            </span>
                          </div>
                        </div>
                      ))
                      : noDataSimple()}
                  </Col>
                </Row>
                {/* <Link className='more-btn mt-0' to="/hours-track?order-by=mostTrackedHours">more</Link> */}
                <p
                  className="more-btn "
                  onClick={handleClickNavigate}
                >
                  {" "}
                  more{" "}
                </p>
              </>
            )}
          </div>
        </div>
      </Col>
    </>
  );
};
export default TrackingMostHours;
