import React from "react";
import { Col, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import CircularProgressBar from "../CircularProgressBar";
import "./style.css";
import { getRandomColor, noDataSimple } from "../../common/GlobalFunctions";
import CustomToolTip from "../CustomToolTip";
import Loading from "../loading";

const TopUsersProductive = (props) => {
  const { loading, dashboardTopUsersProductive, OnUserSelect, dashboardDateRange } = props;
  const navigate = useNavigate();

  const dummySkeletonFunction = () => {
    let dummySkeleton = [];

    for (let i = 0; i < 8; i++) {
      dummySkeleton.push(
        <Col className="mb-4" xs={6} sm={6} md={4} lg={3} key={i}>
          <div className="frame">
            <div>
              <div className="headline"></div>
              <div className="circle-big red">
                <CircularProgressBar
                  value={50}
                  percentage={50}
                  pathColor="#E34444"
                  textColor="#E34444"
                />
              </div>
            </div>
            <div className="names d-flex justify-content-center">
              <span className="d-flex justify-content-center align-items-center p">
                C
              </span>
              <p className="m-0 ms-2">dummy</p>
            </div>
          </div>
        </Col>
      );
    }
    return dummySkeleton;
  };

  const handleClickNavigate = () => {
    const paramsObject = {
      rating: "Productive",
      date: dashboardDateRange.dateRange,
      label: dashboardDateRange.label
    };
    const encodedParams = btoa(JSON.stringify(paramsObject));
    navigate(`/activity-summary?data=${encodedParams}`);
  };

  return (
    <Col className="box m-0 mb-sm-0 mb-lg-2 mb-xl-3" md={12} lg={12} xl={6}>
      <div
        className={
          "top-projects-box toltip-right my-4 mb-0 topusers box-shadow-light overflow-inherit" +
          (loading ? " skeleton1" : "")
        }
      >
        <div className="bg-white p-0 py-3 px-4 border-radius">
          {loading ? (
            <div className="text-center loader my-4 pt-1 height-min">
              <Loading />
            </div>
          ) : (
            <>
              <div className="heading">
                <h3 className="heading heading-font d-flex align-items-center">
                  Most Productive Users
                  <CustomToolTip
                    iconColor="black"
                    details="Users with the highest productivity, based on time spent in apps and websites rated productive."
                  />
                </h3>
                {/* <p className='text-gray fs-14 font-regular'>Highest % time on productive websites and apps</p> */}
              </div>
              <Row className="my-3 mb-0">
                {!loading && dashboardTopUsersProductive.length > 0
                  ? dashboardTopUsersProductive.map((s, index) => (
                    <Col
                      className="mb-4 mb-0 mb-lg-0"
                      xs={6}
                      sm={6}
                      md={4}
                      lg={3}
                      key={index}
                    >
                      <div className="frame">
                        <div>
                          <div className="headline"></div>
                          <div className="circle-big">
                            <CircularProgressBar
                              value={s.percentage}
                              percentage={s.percentage}
                              pathColor="#1A9583"
                              textColor="#1A9583"
                            />
                          </div>
                        </div>
                        <div className="names d-flex justify-content-center">
                          <span
                            style={{
                              backgroundColor: getRandomColor(s.name),
                            }}
                            className="d-flex justify-content-center align-items-center p"
                          >
                            {s.name.charAt(0)}
                          </span>
                          <div className="hover-label">
                            <Link
                              to="/user-dashboard"
                              className="m-0 ms-2"
                              onClick={() => {
                                OnUserSelect({ id: s.id, name: s.name });
                              }}
                            >
                              {s.name}
                            </Link>
                            <p className="name-show">{s.name}</p>
                          </div>
                        </div>
                      </div>
                    </Col>
                  ))
                  : noDataSimple()}
              </Row>
              <p
                className="more-btn"
                onClick={handleClickNavigate}
              >
                more
              </p>
            </>
          )}
        </div>
      </div>
    </Col>
  );
};

export default TopUsersProductive;
