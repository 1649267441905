import React from 'react'
import { useState } from 'react';
import SingleUserDayDetails from '../SingleUserDayDetails';
import './style.css';
import { dateFormater, timeToMinutesConverter, timeFormatInHMS, noDataSimple, timePlusDateToLocalTimeConverter } from '../../common/GlobalFunctions'
import Loading from '../loading';


export default function TimelinesTable(props) {

  const { userDashboardUserTimelineLoading, userDashboardUserTimeline, timeZone, timeZoneOffset } = props;

  const [userDayDetails, setuserDayDetails] = useState();
  const [showSingleUserDayDetails, setShowSingleUserDayDetails] = useState(false);

  const openSingleUserDayDetails = (tl) => {
    setuserDayDetails(tl);
    setShowSingleUserDayDetails(!showSingleUserDayDetails);
  };

  const dateColumnsFunction = () => {
    let dateArray = []
    userDashboardUserTimeline.timeline.forEach((tl, i) => {
      dateArray.push(
        <div key={i}>
          <div className='timeline-head timeline-row bg-white d-flex border-right-left' onClick={() => { if (tl.startTime !== "--:--") { openSingleUserDayDetails(tl) } }}>
            <div className='head'><span><img src={"images/right-arrow.svg"} alt="Right Arrow" />{dateFormater(tl.date)}</span></div>
            <div className='head'><span>{timeFormatInHMS(tl.totalTimeTrackedDB)}</span></div>
            <div className='head'><span>{timePlusDateToLocalTimeConverter(tl.date, tl.startTime, "UTC", "+00:00")}</span></div>
            <div className='head'><span>{timePlusDateToLocalTimeConverter(tl.date, tl.endTime, "UTC", "+00:00")}</span></div>

            <div className='am d-flex justify-content-center align-items-center p-0 me-2'>
              <div className='ms-2  relative-box'>
                {activityBarFunction(i)}
              </div>
            </div>
          </div>
        </div>
      )
    })
    return dateArray
  }

  // const activityBarFunction = (index) => {
  //   let activities = [];
  //   userDashboardUserTimeline.timeline[index].activity.forEach((s, i) => {
  //     activities.push(
  //       <div key={i} className='bg-green absolute-div' style={{ width: `${s.timeTracked * 0.001182}%`, height: "25px", left: `${timeToMinutesConverter(s.startTime, "+00:00") * 0.069444}%` }} >
  //         <span className='toolTip'>
  //           {s.timeOn.projectName}
  //           <br />{"(" + s.timeOn.taskName + ")"}
  //           <br />{timeFormatInHMS(s.timeTracked)}
  //         </span>
  //       </div>)
  //   })
  //   return activities;
  // }

  const activityBarFunction = (index) => {
    let activities = [];
    let previousEndTime = 0; 
    const parentWidth = 1000; 
    const gap = 0; 
    const gapPercentage = (gap / parentWidth) * 100; 
  
    const formatTime = (totalSeconds) => {
      const h = Math.floor(totalSeconds / 3600);
      const m = Math.floor((totalSeconds % 3600) / 60);
      const s = Math.floor(totalSeconds % 60);
      return `${h}h ${m}m ${s.toString().padStart(2, '0')}s`;
    };
  
    userDashboardUserTimeline.timeline[index].activity.forEach((s, i) => {
      const startTime = timeToMinutesConverter(s.startTime, "+00:00") * 0.069444;
      const width = s.timeTracked * 0.001182;
      const left = startTime;
  
      // Calculate not working time if there is a gap between previous end time and current start time
      if (left > previousEndTime) {
        const notWorkingTimeInMinutes = (left - previousEndTime) / 0.069444; 
        const notWorkingTimeInSeconds = notWorkingTimeInMinutes * 60; 
        activities.push(
          <div key={`empty-${i}`} className='bg-notworking absolute-div' style={{ width: `${left - previousEndTime}%`, height: "25px", left: `${previousEndTime}%` }}>
            <span className='toolTip'>
              Not Working
              <br />{formatTime(notWorkingTimeInSeconds)}
            </span>
          </div>
        );
      }
  
      // Create the activity div block with gaps before and after
      activities.push(
        <div key={i} className='bg-green absolute-div' style={{ width: `${width - (2 * gapPercentage)}%`, height: "25px", left: `${left + gapPercentage}%` }}>
          <span className='toolTip'>
            {s.timeOn.projectName}
            <br />{"(" + s.timeOn.taskName + ")"}
            <br />{timeFormatInHMS(s.timeTracked)}
          </span>
        </div>
      );
  
      // Update previous end time considering the gap
      previousEndTime = left + width;
    });
  
    // Add empty div block at the end if there is remaining space
    if (previousEndTime < 100) {
      const notWorkingTimeInMinutes = (100 - previousEndTime) / 0.069444; 
      const notWorkingTimeInSeconds = notWorkingTimeInMinutes * 60; 
      activities.push(
        <div key={`empty-end`} className='bg-notworking absolute-div' style={{ width: `${100 - previousEndTime}%`, height: "25px", left: `${previousEndTime}%` }}>
          <span className='toolTip'>
            Not Working
            <br />{formatTime(notWorkingTimeInSeconds)}
          </span>
        </div>
      );
    }
  
    return activities;
  }
  
  

  const dumyDataBarFunction = () => {
    let activities = [];
    for (let m = 0; m > 5; m++) {
      activities.push(
        <div key={m} className='bg-green absolute-div' style={{ width: `0%`, height: "25px", left: `0%` }}>
        </div>)
    }
    return activities;
  }


  return (
    <>

      {userDashboardUserTimelineLoading ?
        <div className="timelines-table bg-white mt-4 box-shadow-light text-center loader my-4 pt-1 height-min">
          <div className='text-center loader my-4 pt-1 height-min'>
            <Loading />
          </div>
        </div>
        :
        <>
          <div className={"timelines-table mt-4 box-shadow-light"
            + (userDashboardUserTimelineLoading ? " skeleton1 " : "")}>


            <div>
              <h3 className='heading bg-white py-4 px-3 font-bold-500'>Activity Timeline</h3>

              <div className='timeline-head font-size-14  main d-flex border-right-left font-bold-500-span '>
                <div className='head'><span>Date </span></div>
                <div className='head'><span>Time Tracked </span></div>
                <div className='head'><span>Start Time </span></div>
                <div className='head'><span>End Time</span></div>
                <div className='timeline-head-row am d-flex justify-content-start align-items-center p-0'>
                  <span></span>
                  <span>1:00</span>
                  <span></span>
                  {/* <span>2</span> */}
                  <span>3:00</span>
                  <span></span>
                  {/* <span>4</span> */}
                  <span>5:00</span>
                  <span></span>
                  {/* <span>6</span> */}
                  <span>7:00</span>
                  <span></span>
                  {/* <span>8</span> */}
                  <span>9:00</span>
                  <span></span>
                  {/* <span>10</span> */}
                  <span>11:00</span>
                  <span></span>
                  {/* <span>12</span> */}
                  <span>13:00</span>
                  <span></span>
                  {/* <span>14</span> */}
                  <span>15:00</span>
                  <span></span>
                  {/* <span>16</span> */}
                  <span>17:00</span>
                  <span></span>
                  {/* <span>18</span> */}
                  <span>19:00</span>
                  <span></span>
                  {/* <span>20</span> */}
                  <span>21:00</span>
                  <span></span>
                  {/* <span>22</span> */}
                  <span>23:00</span>
                  <span></span>
                  {/* <span>24</span> */}
                </div>
              </div>
            </div>
            {userDashboardUserTimelineLoading ? dumyDataBarFunction() : userDashboardUserTimeline.timeline.some(obj => obj.totalTimeTracked > 0) ? dateColumnsFunction() : noDataSimple()}

          </div>


          {showSingleUserDayDetails && <SingleUserDayDetails userDayDetails={userDayDetails} userName={userDashboardUserTimeline.userName} userId={userDashboardUserTimeline.userId} toltalTimeTracked={userDashboardUserTimeline.timeline.totalTimeTracked} show={showSingleUserDayDetails} handleClose={openSingleUserDayDetails} timeZone="UTC" timeZoneOffset="+00:00" />}

        </>}
    </>
  )
}
