import React from "react";
import { Button, Col, Row } from "react-bootstrap";
import "./style.css";
import {
  catalogNameFormater,
  noDataSimple,
  timeFormat,
  webOrApp,
} from "../../common/GlobalFunctions";
import { Link, useNavigate } from "react-router-dom";
import CustomToolTip from "../CustomToolTip";
import Loading from "../loading";

const ProductiveWebsitesApp = (props) => {
  const { loading, dashboardTopUsedWebsitesAppsProductive, dashboardDateRange } = props;

  const navigate = useNavigate();

  function FindMax(array) {
    return Math.max(...array.map((x) => x.timeTracked));
  }
  function FindPercentange(array, element) {
    return FindMax(dashboardTopUsedWebsitesAppsProductive) <= 0
      ? 0
      : (element * 100) / FindMax(array);
  }

  const dummySkeletonFunction = () => {
    let dummySkeleton = [];

    for (let i = 0; i < 6; i++) {
      dummySkeleton.push(
        <div
          className="line-hours d-flex justify-content-between align-items-center mb-4"
          key={i}
        >
          <div className="names d-flex align-items-center">
            <span className="d-flex justify-content-center align-items-center icon">
              <img src={"images/browser-icon.svg"} alt="--" />
            </span>
            <p className="m-0 ms-2">--</p>
          </div>
          <div className="line">
            <div className="red" style={{ width: "100px" }}>
              {" "}
            </div>
          </div>
          <span className="time">--</span>
        </div>
      );
    }
    return dummySkeleton;
  };

  const handleClickNavigate = () => {
    const paramsObject = {
      rating: "Productive",
      date: dashboardDateRange.dateRange,
      label: dashboardDateRange.label
    };
    const encodedParams = btoa(JSON.stringify(paramsObject));
    navigate(`/webs-apps?data=${encodedParams}`);
  };

  return (
    <>
      <Col
        className="box m-0 mt-2 second-last mt-sm-2 mt-md-2 mt-lg-0 mb-xl-0"
        md={12}
        lg={12}
        xl={6}
      >
        <div
          className="top-projects-box mt-2 mt-sm-1 mt-md-1 mt-lg-1 mt-xl-0 mb-0 box-shadow websitesapps box-shadow-light  overflow-inherit"
        >
          <div className="bg-white p-0  py-2 py-sm-3 px-4 h-100 border-radius">
            {loading ? (
              <div className="text-center loader my-4 pt-1 height-min">
                <Loading />
              </div>
            ) : (
              <>
                <div className="heading">
                  <h3 className="heading heading-font mb-4 align-items-center text-wrap text-sm-nowrap transform-tooltip team-dashboard-tooltips mob-tooltip">
                    Most Used Productive Websites & Apps
                    <CustomToolTip
                      iconColor="black"
                      details="Productive apps and websites users spent the most time in."
                    />
                  </h3>
                </div>
                <Row className="mb-4">
                  <Col sm={12} className="mb-3">
                    {!loading &&
                      dashboardTopUsedWebsitesAppsProductive.length > 0
                      ?
                      <>
                        {dashboardTopUsedWebsitesAppsProductive.map(
                          (s, index) => (
                            <div key={index}>
                              <div className="line-hours d-flex justify-content-between align-items-center mb-4"
                              >
                                <div className="names d-flex align-items-center">
                                  <span className="d-flex justify-content-center align-items-center icon">
                                    <img
                                      src={
                                        webOrApp(s.name) === "web"
                                          ? "images/browser-icon.svg"
                                          : "images/appsicon.svg"
                                      }
                                      alt=""
                                    />
                                  </span>
                                  <p className="m-0 ms-2">
                                    {catalogNameFormater(s.name)}
                                  </p>
                                </div>
                                <div className="line Productive ">
                                  <div
                                    className="d-green progress-bar"
                                    style={{
                                      width: `${FindPercentange(
                                        dashboardTopUsedWebsitesAppsProductive,
                                        s.timeTracked
                                      )}%`,
                                    }}
                                  >
                                    {" "}
                                  </div>
                                </div>
                                <span className="time pe-2 d-flex justify-content-end">
                                  {timeFormat(s.timeTracked)}
                                </span>
                              </div>
                            </div>
                          ))}
                      </>
                      : noDataSimple()}
                  </Col>
                </Row>
                <p
                  className="more-btn mt-0"
                  onClick={handleClickNavigate}
                >
                  more
                </p>
              </>
            )}
          </div>
        </div>
      </Col>
    </>
  );
};
export default ProductiveWebsitesApp;
