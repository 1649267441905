import { ca } from "date-fns/locale";
import CryptoJS from 'crypto-js';
import { NoDataToDisplay } from "../utils/Constants";
import { ADMIN, GROUPADMIN, MEMBER, OWNER } from "../utils/Constants";
import TimezoneSelect from "react-timezone-select";
import { getLocalStorageValue, setLocalStorageValue } from "../utils/PersistanceManager";
import { faL } from "@fortawesome/free-solid-svg-icons";
// import MD5 from "crypto-js/md5";


export function capitalizeTheFirstLetterOfEachWord(words) {
  if (words === undefined || (typeof words !== 'string') || words.length < 1) {
    return "";
  }

  var separateWord = words.toLowerCase().split(' ');
  for (var i = 0; i < separateWord.length; i++) {
    separateWord[i] = separateWord[i].charAt(0).toUpperCase() +
      separateWord[i].substring(1);
  }
  return separateWord.join(' ');
}

export const getActiveTimezone = () => {
  let timezoneData = {
    timeZone: "UTC", timeZoneOffset: "+00:00"
  };

  if (getLocalStorageValue("Active-Timezone")) {
    return JSON.parse(getLocalStorageValue("Active-Timezone"));
  }
  else if (JSON.parse(getLocalStorageValue("Selected_Company"))) {
    let timeZone = JSON.parse(getLocalStorageValue("Selected_Company")).timeZone;
    let timeZoneOffset = JSON.parse(getLocalStorageValue("Selected_Company")).timeZoneOffset;
    if (timeZone === undefined || (typeof timeZone !== 'string') || timeZone.length < 1
      || timeZoneOffset === undefined || (typeof timeZoneOffset !== 'string') || timeZoneOffset.length < 1) {

      if (JSON.parse(getLocalStorageValue("User_Profile"))) {
        timeZone = JSON.parse(getLocalStorageValue("User_Profile")).timeZone;
        timeZoneOffset = JSON.parse(getLocalStorageValue("User_Profile")).timeZoneOffset;

        if (timeZone === undefined || (typeof timeZone !== 'string') || timeZone.length < 1
          || timeZoneOffset === undefined || (typeof timeZoneOffset !== 'string') || timeZoneOffset.length < 1) {

          setLocalStorageValue("Active-Timezone", JSON.stringify(timezoneData));
          return timezoneData;

        }

        else {
          timezoneData.timeZone = JSON.parse(getLocalStorageValue("User_Profile")).timeZone;
          timezoneData.timeZoneOffset = JSON.parse(getLocalStorageValue("User_Profile")).timeZoneOffset;
          setLocalStorageValue("Active-Timezone", JSON.stringify(timezoneData));
          return timezoneData;
        }

      }
    }
    else {
      timezoneData.timeZone = JSON.parse(getLocalStorageValue("Selected_Company")).timeZone;
      timezoneData.timeZoneOffset = JSON.parse(getLocalStorageValue("Selected_Company")).timeZoneOffset;
      setLocalStorageValue("Active-Timezone", JSON.stringify(timezoneData));
      return timezoneData;
    }
    // setLocalStorageValue("Active-Timezone", JSON.stringify(timezoneData));
    // return timezoneData;
  }
  else if (JSON.parse(getLocalStorageValue("User_Profile"))) {
    timezoneData.timeZone = JSON.parse(getLocalStorageValue("User_Profile")).timeZone;
    timezoneData.timeZoneOffset = JSON.parse(getLocalStorageValue("User_Profile")).timeZoneOffset;
    setLocalStorageValue("Active-Timezone", JSON.stringify(timezoneData));
    return timezoneData;
  }
  else {
    console.log("else callled");
    // setLocalStorageValue("Active-Timezone", JSON.stringify(timezoneData));
    return timezoneData;
  }
}
export const padToXDigits = (number, digit) => {
  return number.toString().padStart(digit, '0');
}
export const dateForComparison = (date) => {
  date = new Date(date);
  var finalDate = date.getFullYear() + '-' + padToXDigits(date.getMonth() + 1, 2).toString() + '-' + padToXDigits(date.getDate(), 2).toString();
  return finalDate.toString();
}

export function isoToCustom(isoDate) {
  const date = new Date(isoDate);
  const options = { day: '2-digit', month: 'short', year: 'numeric' };
  return date.toLocaleDateString('en-US', options).toUpperCase();
}

export function getTextColorForBackground(backgroundColor) {
  const bg = backgroundColor.substr(4).split(',');
  const r = parseInt(bg[0], 16);
  const g = parseInt(bg[1], 16);
  const b = parseInt(bg[2], 16);
  const yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;
  return yiq >= 225 ? '#000000' : '#ffffff';
}
export function reverse(str, chr) {
  return str.split(chr).reverse().join(chr);
}
export function timeFormat(totalSeconds) {
  if (totalSeconds > 0) {
    const totalMinutes = Math.floor(totalSeconds / 60);
    const minutes = totalMinutes % 60;
    const hours = Math.floor(totalMinutes / 60);
    return `${hours > 0 && hours < 10 ? padToXDigits(hours, 2) : padToXDigits(hours, 1)}h ${minutes > 0 && minutes < 10 ? padToXDigits(minutes, 2) : padToXDigits(minutes, 1)}m`;
  }
  else {
    return `${padToXDigits(0, 1)}h ${padToXDigits(0, 1)}m`;
  }
}
export function HourOrMinOrSec(totalSeconds) {
  if (totalSeconds > 0) {
    const totalMinutes = Math.floor(totalSeconds / 60);
    const minutes = totalMinutes % 60;
    const hours = Math.floor(totalMinutes / 60);
    const res = `${hours > 0 && hours < 10 ? padToXDigits(hours, 2) : padToXDigits(hours, 1)}h ${minutes > 0 && minutes < 10 ? padToXDigits(minutes, 2) : padToXDigits(minutes, 1)}m`;
    if (hours > 0) return hours + " H";
    if (minutes > 0) return minutes + " M";
    return totalSeconds + " S";
  }
  else {
    return 0;
  }
}
export function timeFormatInHMS(totalSeconds) {
  if (totalSeconds > 0) {
    totalSeconds = Math.round(totalSeconds)
    const seconds = totalSeconds % 60;
    const totalMinutes = Math.floor(totalSeconds / 60);
    const minutes = totalMinutes % 60;
    const hours = Math.floor(totalMinutes / 60);
    return `${padToXDigits(hours, 1)}h ${padToXDigits(minutes, 1)}m ${padToXDigits(seconds, 1)}s`;
  }
  else {
    return `${padToXDigits(0, 1)}h ${padToXDigits(0, 1)}m ${padToXDigits(0, 1)}s`;
  }
}
export function minutesColonSecondsToSec(val) {
  if (val === undefined || (typeof val !== 'string') || val.length < 1) {
    return "";
  }

  const parts = val.split(":");
  const min = parseInt(parts[0]) * 60;
  const sec = parseInt(parts[1]);
  const totalSec = min + sec;
  return totalSec;
}
export function timeFormatInHMorMS(totalSeconds) {
  if (totalSeconds > 0) {
    totalSeconds = Math.round(totalSeconds)
    const seconds = totalSeconds % 60;
    const totalMinutes = Math.floor(totalSeconds / 60);
    const minutes = totalMinutes % 60;
    const hours = Math.floor(totalMinutes / 60);
    if (totalSeconds < 3600) {
      return `${minutes > 0 && minutes < 10 ? padToXDigits(minutes, 2) : padToXDigits(minutes, 1)}m ${seconds > 0 && seconds < 10 ? padToXDigits(seconds, 2) : padToXDigits(seconds, 1)}s`;
    }
    else {
      return `${hours > 0 && hours < 10 ? padToXDigits(hours, 2) : padToXDigits(hours, 1)}h ${minutes > 0 && minutes < 10 ? padToXDigits(minutes, 2) : padToXDigits(minutes, 1)}m`;
    }
  }
  else {
    return `${padToXDigits(0, 1)}m ${padToXDigits(0, 1)}s`;
  }
}
export const dateFormater = (date) => {
  var Fdate = new Date(date)
  return Fdate.toLocaleDateString('en-us', { weekday: "short", day: "numeric", month: "short" }); //,year:"numeric"
}
export const isoTolocalDateTime = (isoDate) => {
  return new Date(isoDate).toLocaleString('en-US',{
    day: '2-digit',
    month: 'short',
    year: 'numeric',
  }) + ' ' + new Date(isoDate).toLocaleTimeString('en-US', {
    hour: '2-digit',
    minute: '2-digit',
    hour12: true
  })
}
export const fixingTimeZone = (timeZoneOffset) => {
  const [hours, minutes] = timeZoneOffset.split(':');
  const formattedOffset = parseFloat(`${hours}.${minutes || 0}`);
  return formattedOffset;
}
export const timeToMinutesConverter = (time, timeZoneOffset) => {
  let hour = time.slice(0, 2);
  let minutes = time.slice(3);
  let hourtominute = hour * 60;
  let totalminutes = parseInt(minutes) + parseInt(hourtominute);

  return totalminutes + (fixingTimeZone(timeZoneOffset) * 60);
  // return totalminutes - (new Date().getTimezoneOffset());
}
export const isoToLocalTimeConverter = (iso) => {

  var locale = new Date(iso);

  var hours = locale.getHours() < 10 ? "0" + locale.getHours() : locale.getHours();
  var hour = hours < 13 ? hours : hours - 12;
  var am_pm = locale.getHours() >= 12 ? "PM" : "AM";
  var minute = locale.getMinutes() < 10 ? "0" + locale.getMinutes() : locale.getMinutes();
  return hour + ":" + minute + am_pm;

}
export const timePlusDateToLocalTimeConverter = (date, time, timeZone) => {
  if (time !== "--:--") {
    var datetime = date + "T" + time + ":00.000Z";
    const options = {
      timeZone: timeZone,
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    };
    const formattedDate = new Date(datetime).toLocaleString('en-US', options);
    return formattedDate;
  }
  else
    return "--:--"
}
export function toIsoString(date, timeZoneOffset) {
  const off = date.getTimezoneOffset()
  const absoff = Math.abs(off)

  return (new Date(date.getTime() - off * 60 * 1000).toISOString().substring(0, 23) +
    (off > 0 ? '-' : '+') +
    Math.floor(absoff / 60).toFixed(0).padStart(2, '0') + ':' +
    (absoff % 60).toString().padStart(2, '0'))
}

// used in screenshort module
export const isTodayEndDate = (dateString) => {
  const givenDate = new Date(dateString);
  const today = new Date();

  const isSameDate =
    givenDate.getFullYear() === today.getFullYear() &&
    givenDate.getMonth() === today.getMonth() &&
    givenDate.getDate() === today.getDate();

  if (isSameDate) {
    givenDate.setHours(today.getHours(), today.getMinutes(), today.getSeconds(), today.getMilliseconds());
    return givenDate.toString(); 
  } else {
    givenDate.setHours(23, 59, 59, 0);
    return givenDate.toString();
  }
}

export const formatScreenShotDate = (dateStr, startEnd) => {
    if (startEnd === 0) {
      dateStr.setHours(0);
      dateStr.setMinutes(0);
      dateStr.setSeconds(0);
    }else {
        const today = new Date();
        const isSameDate =
        dateStr.getFullYear() === today.getFullYear() &&
        dateStr.getMonth() === today.getMonth() &&
        dateStr.getDate() === today.getDate();
  
        if (isSameDate) {
          const date = new Date(dateStr);
  
          // Extract date components
          const year = date.getFullYear();
          const month = String(date.getMonth() + 1).padStart(2, '0'); 
          const day = String(date.getDate()).padStart(2, '0');
          const hours = String(date.getHours()).padStart(2, '0');
          const minutes = String(date.getMinutes()).padStart(2, '0');
          const seconds = String(date.getSeconds()).padStart(2, '0');
          
          // Get timezone offset in hours and minutes
          const offset = -date.getTimezoneOffset();
          const offsetHours = String(Math.floor(Math.abs(offset) / 60)).padStart(2, '0');
          const offsetMinutes = String(Math.abs(offset) % 60).padStart(2, '0');
          const offsetSign = offset >= 0 ? '+' : '-';
          
          // Format as ISO 8601 string
          const formattedDate = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.000${offsetSign}${offsetHours}:${offsetMinutes}`;
        
          return formattedDate;
        }else{
          dateStr.setHours(23);
          dateStr.setMinutes(59);
          dateStr.setSeconds(59);
        }
    }
    return systemTimeToUserTime(dateStr);
  }

// used in screenshort module

export function toIsoDate(date, startEnd) {
  if (startEnd === 0) {
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
  }
  else {
    date.setHours(23);
    date.setMinutes(59);
    date.setSeconds(59);
  }
  return systemTimeToUserTime(date);
}

export function systemTimeToUserTime(dateTimeString) {
  const userProfileJSON = getLocalStorageValue("User_Profile");
  if (userProfileJSON) {
    const userProfile = JSON.parse(userProfileJSON);
    if (userProfile && userProfile.timeZoneOffset !== undefined && userProfile.timeZoneOffset !== null) {
      const timeZoneOffset = userProfile.timeZoneOffset;
      return toIsoString(dateTimeString).slice(0, -6) + timeZoneOffset;
    } else {
      return toIsoString(dateTimeString);
    }
  }
  else return toIsoString(dateTimeString);
}
export const dateToDateString = (isoDate, timeZone) => {
  const options = {
    timeZone: timeZone,
    weekday: 'short',
    month: 'short',
    day: 'numeric',
    year: 'numeric',
  };

  const formattedDate = new Date(isoDate).toLocaleDateString('en-US', options);
  return formattedDate;
}
export const dateToHour = (date, timeZone) => {

  const options = {
    timeZone: timeZone,
    hour: 'numeric',
    hour12: true,
  };
  const formattedDate = new Date(date).toLocaleString('en-US', options);
  return formattedDate;
}
export const dateToTime = (date, timeZone) => {
  const options = {
    timeZone: timeZone,
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  };
  const formattedDate = new Date(date).toLocaleString('en-US', options);
  return formattedDate;

}
export const getcharacter = (string) => {
  if (string?.length > 0)
    return string.substring(0, 1);
  else return "";
}
export const catalogNameFormater = (string) => {
  if (string.includes(".") && !string.includes(" ")) {
    return string.toLowerCase();
  }
  else {
    return string.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
  }
}
export const webOrApp = (string) => {
  if (string.includes(".") && !string.includes(" ")) {
    return "web";
  }
  else {
    return "app";
  }
}
export const noDataGraph = () => {
  return <div className='no-data'>
    <img src={"images/NoGraphData.svg"} />
    <p> {NoDataToDisplay} </p>
  </div>
}
export const noDataSimple = () => {
  return <div className='no-data'>
    <img src={"images/NoData.svg"} />
    <p> {NoDataToDisplay} </p>
  </div>
}
export const allowedBasedOnRole = (allowedRoles, userRoleArray) => {
  const cArray = allowedRoles.filter(value => userRoleArray.some(role => role.toUpperCase() === (value)));
  if (cArray.length > 0) return true;
  else return false;
}

export const allowedBasedOnPackage = (allowedPackages, currentPackage) => {
  const tuple = { minPackage: "", isAllowed: true };
  if (currentPackage === undefined || (typeof currentPackage !== 'string') || currentPackage.length < 1) {
    return tuple
  }

  const cArray = allowedPackages.filter(value => currentPackage.toUpperCase() === value);
  if (cArray.length > 0) {
    tuple.isAllowed = true;
    return tuple;
  }
  else {
    tuple.minPackage = allowedPackages[0];
    tuple.isAllowed = false;
    return tuple;
  }

}
export function getRandomColor(name) {
  const colorCodes = ['#7179df', '#1eb49c', '#ff984d', '#1abbf0', '#e499ed', '#67e58b', '#ff9390', '#5eeaff'];
  const md5Hash = CryptoJS.MD5(name).toString();
  const firstChar = md5Hash.charAt(0);
  const index = parseInt(firstChar, 16) % colorCodes.length;
  return colorCodes[index];
}

export const getOperatingSystem = () => {
  const userAgent = navigator.userAgent.toLowerCase();

  if (userAgent.includes('win')) {
    return 'Windows';
  } else if (userAgent.includes('mac')) {
    return 'Mac OS';
  } else if (userAgent.includes('linux')) {
    return 'Linux';
  } else if (userAgent.includes('android')) {
    return 'Android';
  } else if (userAgent.includes('ios')) {
    return 'iOS';
  } else {
    return 'Unknown';
  }
};

export const capitaliseEachFirstLater = (str) => {

  if (str === undefined || (typeof str !== 'string') || str.length < 1) {
    return "";
  }

  let transformedStr = str.replace(/_/g, ' ');

  transformedStr = transformedStr.toLowerCase().replace(/\b\w/g, function (char) {
    return char.toUpperCase();
  });

  return transformedStr;
}

export function formatDatesISO(dateString) {
  const dateStrings = dateString.split(',');
  const dateObjects = dateStrings.map(dateStr => new Date(dateStr.trim()));
  return dateObjects.slice(0, 2);
}

export   const getAppliedDateRange = (data_param) => {
  const trackedDate = data_param;
  
  const formatDate = date => {
    const d = new Date(date);
    return ('0' + d.getDate()).slice(-2) + '-' + ('0' + (d.getMonth() + 1)).slice(-2) + '-' + d.getFullYear();
  };

  const minDate = new Date(Math.min(...trackedDate.map(date => new Date(date))));
  const maxDate = new Date(Math.max(...trackedDate.map(date => new Date(date))));

  return formatDate(minDate) + ' TO ' + formatDate(maxDate);
};

