import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import SingleUserHoursDetails from '../SingleUserHoursDetails';
import './style.css';
import { timeFormatInHMS, timeToMinutesConverter, getcharacter, timePlusDateToLocalTimeConverter } from '../../common/GlobalFunctions'

export default function SingleUserDayDetails(props) {
  const { show, handleClose, userDayDetails, userName, userId, timeZone, timeZoneOffset } = props

  const [userHourDetails, setuserHourDetails] = useState();
  const [showSingleUserHoursDetails, setSingleUserHoursDetails] = useState(false);
  const [hoveredElement, setHoveredElement] = useState(null);
  const [isChecked24, setIsChecked24] = useState(false);


  const processTimeEntries = () => {
    let entries = userDayDetails.activity;
    const parseTime = (timeString) => {
        const [hours, minutes] = timeString.split(':').map(Number);
        return new Date(1970, 0, 1, hours, minutes);
    };

    const formatTime = (date) => {
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        return `${hours}:${minutes}`;
    };

    const addMinutes = (date, minutes) => {
        return new Date(date.getTime() + minutes * 60000);
    };

    const getDifferenceInSeconds = (date1, date2) => {
        return Math.floor((date2 - date1) / 1000);
    };

    const generateId = (startTime, endTime) => {
        return `${startTime.replace(':', '')}-${endTime.replace(':', '')}`;
    };

    let newEntries = [];

    if (entries.length > 0 && isChecked24) {
        // Add empty block at the beginning if the first entry doesn't start at 00:00
        const firstEntry = entries[0];
        if (firstEntry.startTime !== '00:00') {
            const firstStartTime = parseTime(firstEntry.startTime);
            const newEndTime = addMinutes(firstStartTime, -1);
            const formattedEndTime = formatTime(newEndTime);
            newEntries.push({
                date: firstEntry.date,
                startTime: '00:00',
                endTime: formattedEndTime,
                startDateTime: `${firstEntry.date} 00:00`,
                endDateTime: `${firstEntry.date} ${formattedEndTime}`,
                timeTracked: getDifferenceInSeconds(parseTime('00:00'), newEndTime),
                timeTrackedDB: getDifferenceInSeconds(parseTime('00:00'), newEndTime),
                projectId: '',
                taskId: '',
                timeOn: {
                    projectId: '',
                    projectName: '',
                    taskId: '',
                    taskName: ''
                },
                id: generateId('00:00', formattedEndTime)
            });
        }

        for (let i = 0; i < entries.length - 1; i++) {
            const currentEntry = entries[i];
            const nextEntry = entries[i + 1];

            newEntries.push({
                ...currentEntry,
                id: generateId(currentEntry.startTime, currentEntry.endTime)
            });

            const currentEndTime = parseTime(currentEntry.endTime);
            const nextStartTime = parseTime(nextEntry.startTime);

            if (nextStartTime - currentEndTime > 60000) { // More than one minute difference
                const newStartTime = addMinutes(currentEndTime, 1);
                const newEndTime = addMinutes(nextStartTime, -1);
                const totalSeconds = getDifferenceInSeconds(newStartTime, newEndTime);
                const formattedStartTime = formatTime(newStartTime);
                const formattedEndTime = formatTime(newEndTime);
                const newNode = {
                    date: currentEntry.date,
                    startTime: formattedStartTime,
                    endTime: formattedEndTime,
                    startDateTime: `${currentEntry.date} ${formattedStartTime}`,
                    endDateTime: `${currentEntry.date} ${formattedEndTime}`,
                    timeTracked: totalSeconds,
                    timeTrackedDB: totalSeconds,
                    projectId: '',
                    taskId: '',
                    timeOn: {
                        projectId: '',
                        projectName: '',
                        taskId: '',
                        taskName: ''
                    },
                    id: generateId(formattedStartTime, formattedEndTime)
                };
                newEntries.push(newNode);
            }
        }

        const lastEntry = entries[entries.length - 1];
        newEntries.push({
            ...lastEntry,
            id: generateId(lastEntry.startTime, lastEntry.endTime)
        });

        // Add empty block at the end if the last entry doesn't end at 24:00
        if (lastEntry.endTime !== '24:00') {
            const lastEndTime = parseTime(lastEntry.endTime);
            const newStartTime = addMinutes(lastEndTime, 1);
            const formattedStartTime = formatTime(newStartTime);
            newEntries.push({
                date: lastEntry.date,
                startTime: formattedStartTime,
                endTime: '24:00',
                startDateTime: `${lastEntry.date} ${formattedStartTime}`,
                endDateTime: `${lastEntry.date} 24:00`,
                timeTracked: getDifferenceInSeconds(newStartTime, parseTime('24:00')),
                timeTrackedDB: getDifferenceInSeconds(newStartTime, parseTime('24:00')),
                projectId: '',
                taskId: '',
                timeOn: {
                    projectId: '',
                    projectName: '',
                    taskId: '',
                    taskName: ''
                },
                id: generateId(formattedStartTime, '24:00')
            });
        }
    }else if(entries.length > 0 && !isChecked24) {
      for (let i = 0; i < entries.length; i++) {
        const currentEntry = entries[i];

        newEntries.push({
            ...currentEntry,
            id: generateId(currentEntry.startTime, currentEntry.endTime)
        });
      }
    }

    return newEntries;
  };



  const openSingleUserHoursDetails = (hr) => {
    setuserHourDetails(hr);
    setSingleUserHoursDetails(!showSingleUserHoursDetails);
  };

  const generateId = (startTime, endTime) => {
    return `${startTime.replace(':', '')}-${endTime.replace(':', '')}`;
  };
  
  const activityBarFunction = () => {
    let activities = [];
    let previousEndTimeMinutes = 0;
    const parentWidth = 1000;
    const gap = 0;
    const gapPercentage = (gap / parentWidth) * 100;
  
    const formatTime = (totalSeconds) => {
      const h = Math.floor(totalSeconds / 3600);
      const m = Math.floor((totalSeconds % 3600) / 60);
      const s = Math.floor(totalSeconds % 60);
      return `${h}h ${m}m ${s.toString().padStart(2, '0')}s`;
    };
  
    const timeToMinutes = (time) => {
      const [hours, minutes] = time.split(':').map(Number);
      return hours * 60 + minutes;
    };
  
    const minutesToTime = (minutes) => {
      const h = Math.floor(minutes / 60);
      const m = Math.floor(minutes % 60);
      return `${h.toString().padStart(2, '0')}:${m.toString().padStart(2, '0')}`;
    };
  
    // Check if there is a gap before the first activity
    if (userDayDetails.activity.length > 0) {
      const firstActivityStartTime = timeToMinutes(userDayDetails.activity[0].startTime);
      if (firstActivityStartTime > 0) {
        // Create an empty block from 00:00 to one minute before the first activity
        const gapEndMinutes = firstActivityStartTime - 1;
        activities.push(
          <div
            onMouseEnter={() => handleMouseEnter(generateId('00:00', minutesToTime(gapEndMinutes)))}
            onMouseLeave={handleMouseLeave}
            className={`bg-notworking absolute-div ${hoveredElement === generateId('00:00', minutesToTime(gapEndMinutes)) ? 'block-hover-active' : ''} `}
            style={{ width: `${gapEndMinutes * 0.069444}%`, height: "25px", left: `0%` }}>
            <span className='toolTip'>
              Not Working
              <br />{formatTime(firstActivityStartTime * 60)}
            </span>
          </div>
        );
        previousEndTimeMinutes = firstActivityStartTime;
      }
    }
  
    userDayDetails.activity.forEach((s, i) => {
      const startTimeMinutes = timeToMinutes(s.startTime);
      const endTimeMinutes = timeToMinutes(s.endTime);
      const width = (endTimeMinutes - startTimeMinutes) * 0.069444;
      const left = startTimeMinutes * 0.069444;
  
      const formattedStartTime = s.startTime;
      const formattedEndTime = s.endTime;
      const uniqueId = generateId(formattedStartTime, formattedEndTime);
  
      // Create empty block if there is a gap of one minute or more
      if (startTimeMinutes - previousEndTimeMinutes >= 1) {
        const gapStartMinutes = previousEndTimeMinutes + 1;
        const gapEndMinutes = startTimeMinutes - 1;
        const gapStartTime = minutesToTime(gapStartMinutes);
        const gapEndTime = minutesToTime(gapEndMinutes);
        const gapWidth = (gapEndMinutes - gapStartMinutes + 1) * 0.069444;
  
        activities.push(
          <div
            onMouseEnter={() => handleMouseEnter(generateId(gapStartTime, gapEndTime))}
            onMouseLeave={handleMouseLeave}
            className={`bg-notworking absolute-div ${hoveredElement === generateId(gapStartTime, gapEndTime) ? 'block-hover-active' : ''} `}
            style={{ width: `${gapWidth}%`, height: "25px", left: `${gapStartMinutes * 0.069444}%` }}>
            <span className='toolTip'>
              Not Working
              <br />{formatTime((gapEndMinutes - gapStartMinutes + 1) * 60)}
            </span>
          </div>
        );
      }
  
      // Create the activity block
      activities.push(
        <div
          onMouseEnter={() => handleMouseEnter(uniqueId)}
          onMouseLeave={handleMouseLeave}
          className={`bg-green absolute-div ${hoveredElement === uniqueId ? 'block-hover-active' : ''} `}
          style={{ width: `${width - (2 * gapPercentage)}%`, height: "25px", left: `${left + gapPercentage}%` }}>
          <span className='toolTip'>
            {s.timeOn.projectName}
            <br />{"(" + s.timeOn.taskName + ")"}
            <br />{formattedStartTime} - {formattedEndTime}
          </span>
        </div>
      );
  
      previousEndTimeMinutes = endTimeMinutes;
    });
  
    // Add empty block at the end if there is remaining space
    if (previousEndTimeMinutes * 0.069444 < 100) {
      const endGapStartMinutes = previousEndTimeMinutes + 1;
      const endGapWidth = (1440 - endGapStartMinutes) * 0.069444;
      const endGapStartTime = minutesToTime(endGapStartMinutes);
      const endGapEndTime = '24:00';
  
      activities.push(
        <div
          onMouseEnter={() => handleMouseEnter(generateId(endGapStartTime, endGapEndTime))}
          onMouseLeave={handleMouseLeave}
          className={`bg-notworking absolute-div ${hoveredElement === generateId(endGapStartTime, endGapEndTime) ? 'block-hover-active' : ''} `}
          style={{ width: `${endGapWidth}%`, height: "25px", left: `${endGapStartMinutes * 0.069444}%` }}>
          <span className='toolTip'>
            Not Working
            <br />{formatTime(((24 * 60) - endGapStartMinutes) * 60)}
          </span>
        </div>
      );
    }
  
    return activities;
  };
  
  const handlecheck = (event) => {
    setIsChecked24(event.target.checked);
  }
  
  const handleMouseEnter = (elementName) => {
    setHoveredElement(elementName);
  };

  const handleMouseLeave = () => {
    setHoveredElement(null);
  };

  const formatedDate = `${new Date(userDayDetails.date).toLocaleDateString('en-US', { weekday: 'short' })} ${new Date(userDayDetails.date).toLocaleDateString('en-US', { month: 'short', day: 'numeric' })}, ${new Date(userDayDetails.date).toLocaleDateString('en-US', { year: 'numeric' })}`;
  // console.log(formatedDate);

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        className="overly timelines-modal">
        <Modal.Header className='user-dashboard-modal-header' closeButton>
          <Modal.Title className='w-100'>
           <div className='d-flex justify-content-between'>
              <div className='head-model d-flex align-items-center mt-2'>
                <span className='d-flex justify-content-center pt-1 align-items-center fs-16 font-bold me-2 pb-1'>{getcharacter(userName)}</span>
                <p className='m-0 modal-heading'>{userName}</p>
              </div>
              <div className='day-and-date'>
                <p className='date-right text-nowrap'>{formatedDate}</p>
              </div>
           </div>
          </Modal.Title>
          
        </Modal.Header>
        <Modal.Body className='p-0'>
          <div className="d-block d-md-flex d-lg-none justify-content-end">
                 <p className='d-none time-date time-placement d-md-none'>{new Date(userDayDetails.date).toDateString()}</p>
                <p className="d-flex d-md-block d-lg-none justify-content-end pe-3 text-bold">
              <span className='me-2'>Show Inactivity Times</span>
                  <input type="checkbox" checked={isChecked24} onChange={handlecheck} /></p>
            </div>
          <div className='overview-box'>

            <div className='time-am position-relative'>
            <div className='am-two d-flex justify-content-between align-items-center'>
                <p className='m-0'>
                  <div className='line'></div>
                  {/* <span>0</span> */}
                </p>
                <p className='m-0'>
                  <div className='line'></div>
                  <span>1:00</span>
                </p>
                <p className='m-0'>
                  <div className='line'></div>
                  {/* <span>2</span> */}
                </p>
                <p className='m-0'>
                  <div className='line'></div>
                  <span>3:00</span>
                </p>
                <p className='m-0'>
                  <div className='line'></div>
                  {/* <span>4</span> */}
                </p>
                <p className='m-0'>
                  <div className='line'></div>
                  <span>5:00</span>
                </p>
                <p className='m-0'>
                  <div className='line'></div>
                  {/* <span>6</span> */}
                </p>
                <p className='m-0'>
                  <div className='line'></div>
                  <span>7:00</span>
                </p>
                <p className='m-0'>
                  <div className='line'></div>
                  {/* <span>8</span> */}
                </p>
                <p className='m-0'>
                  <div className='line'></div>
                  <span>9:00</span>
                </p>
                <p className='m-0'>
                  <div className='line'></div>
                  {/* <span>10</span> */}
                </p>
                <p className='m-0'>
                  <div className='line'></div>
                  <span>11:00</span>
                </p>
                <p className='m-0'>
                  <div className='line'></div>
                  {/* <span>12</span> */}
                </p>
                <p className='m-0'>
                  <div className='line'></div>
                  <span>13:00</span>
                </p>
                <p className='m-0'>
                  <div className='line'></div>
                  {/* <span>14</span> */}
                </p>
                <p className='m-0'>
                  <div className='line'></div>
                  <span>15:00</span>
                </p>
                <p className='m-0'>
                  <div className='line'></div>
                  {/* <span>16</span> */}
                </p>
                <p className='m-0'>
                  <div className='line'></div>
                  <span>17:00</span>
                </p>
                <p className='m-0'>
                  <div className='line'></div>
                  {/* <span>18</span> */}
                </p>
                <p className='m-0'>
                  <div className='line'></div>
                  <span>19:00</span>
                </p>
                <p className='m-0'>
                  <div className='line'></div>
                  {/* <span>20</span> */}
                </p>
                <p className='m-0'>
                  <div className='line'></div>
                  <span>21:00</span>
                </p>
                <p className='m-0'>
                  <div className='line'></div>
                  {/* <span>22</span> */}
                </p>
                <p className='m-0'>
                  <div className='line'></div>
                  <span>23:00</span>
                </p>
                <p className='m-0'>
                  <div className='line'></div>
                  {/* <span>24</span> */}
                </p>

              </div>

              <div className=' absolute-box d-flex justify-content-center align-items-center'>
                {activityBarFunction()}
              </div>
            </div>

            <div className=' p-3 over-box box-info d-flex  flex-column flex-md-row justify-content-center align-items-center pt-4 pb-2'>
              <h3 className=' m-0 fs-16 d-md-none d-none font-bold-500 mb-2 mb-md-0 heading'>Overview</h3>

              <div className='clock  d-flex justify-content-between'>
      
                <p>
                  <img src={"images/clock.svg"} className="me-1" />
                  <span> Worked:</span>{timeFormatInHMS(userDayDetails.totalTimeTracked)}
                </p>
                <p className="img-icon-fix">
                  <img src={"images/arrow.svg"} className="me-0" />
                  <span>Working Time Start:</span> {timePlusDateToLocalTimeConverter(userDayDetails.date, userDayDetails.startTime, "UTC")}
                </p>
                <p className='last'>
                  <span>Working Time End:</span> {timePlusDateToLocalTimeConverter(userDayDetails.date, userDayDetails.endTime, "UTC")}
                </p>
                <p className=" d-none d-lg-flex ">
                  <span className='me-2 font-weight-500'>Show Inactivity Times</span>
                  <input type="checkbox" checked={isChecked24} onChange={handlecheck} /></p>
              </div>
            </div>

          </div>

          <div className='timelines-table timelinetable bg-white mb-4 timeline-inner-table' >
            <div className='timeline-head d-flex timeline-header-setting' >
              <div className='head'><span>Start </span></div>
              <div className='head'><span>End</span></div>
              <div className='head'><span>Worked</span></div>
              <div className='head'><span className='ms-lg-2'>Project</span></div>
              <div className='head'><span className='ms-lg-2'>Task</span></div>
            </div>

            <div className='scroll-table style-1 user-timeline-first-table'>
            {processTimeEntries().length > 0 ?
              processTimeEntries().map((a, index) => (
                <div className={`timeline-head timeline-row bg-white d-flex ${(a.projectId).length === 0 ? 'not_works' : ''} ${hoveredElement === a.id ? 'block-hover-active' : ''}`} key={index} onClick={() => { if (a.projectId.length !== 0)  openSingleUserHoursDetails(a); }} 
                onMouseEnter={() => handleMouseEnter(a.id)}
                onMouseLeave={handleMouseLeave}>
                  <div className='head'><span><img src={"images/right-arrow.svg"} />{timePlusDateToLocalTimeConverter(userDayDetails.date, a.startTime, "UTC")}</span></div>
                  <div className='head'><span>{timePlusDateToLocalTimeConverter(userDayDetails.date, a.endTime, "UTC")}</span></div>
                  <div className='head'><span>{timeFormatInHMS(a.timeTracked)}</span></div>
                  <div className='head'><span>{(a.timeOn.projectName).length > 0 ? a.timeOn.projectName : 'Not Working'}</span></div>
                  <div className='head'><span>{(a.timeOn.taskName).length > 0 ? a.timeOn.taskName : 'Not Working'} </span></div>
                </div>
              )) : ''}
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {showSingleUserHoursDetails && <SingleUserHoursDetails userHourDetails={userHourDetails} date={userDayDetails.date} userName={userName} userId={userId} activity={userDayDetails.activity} show={showSingleUserHoursDetails} handleClose={openSingleUserHoursDetails} />}
    </>
  );
}
