import React, { useState, useEffect } from 'react'
import { Col, Container, Dropdown, DropdownButton, Modal, Nav, Row } from 'react-bootstrap'
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { Link, useNavigate } from 'react-router-dom';
import './style.css';
import { logout } from "../../redux/actions/AuthAction";
import { useDispatch, connect } from "react-redux";
import AddUserModal from '../AddUserModal';
import ProfileView from '../ProfileView';
import { fetchCompanyProfileSettings } from "../../redux/actions/CompanyProfileAction";
import CompanyProfile from '../CompanyProfile';
import { allowedBasedOnRole, getActiveTimezone } from '../../common/GlobalFunctions';
import { VERSION } from '../../utils/Constants';
import Loading from '../loading';
import { SELECTED_COMPANY_SELECTION_SUCCESS } from '../../redux/actions/Types';
import { getLocalStorageValue, removeLocalStorageValue, setLocalStorageValue } from '../../utils/PersistanceManager';
import GuideView from '../GuideView';
import { useHistory } from 'react-router-dom';
import CompanyTimeZone from '../CompanyTimeZone';

const Header = (props) => {
  const { leftNavExpand, isClientView, selectedCompany, userProfile, title, description, reactLink, userCompanies, singleUserData, fullUrl, contentUrl } = props;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [showUpdatePanel, setShowUpdatePanel] = useState(false);
  const [companySettingsLoading, setCompanySettingsLoading] = useState(false);
  const [profileImgLoading, setProfileImgLoading] = useState(false);

  useEffect(() => {
    if (reactLink?.length > 0) {
      if (VERSION !== reactLink[0].version) {
        setShowUpdatePanel();
      }
    }
  }, [reactLink])
  
  const logOut = () => {
    dispatch(logout());
    // Navigate to the login page and replace the current entry in the history stack
    navigate("/login", { replace: true });
    
    // Clear browser history and push an empty state
    window.history.pushState(null, null, window.location.href);
    window.onpopstate = function () {
      window.history.go(1);
    };
  };
  

  const [showCompanyView, setShowCompanyView] = useState(false);
  const openCompanyView = () => {
    dispatch(fetchCompanyProfileSettings(selectedCompany.id))
      .then(() => {
        setCompanySettingsLoading(true);
      })
      .catch((error) => {
        setCompanySettingsLoading(false);
      });
    setShowCompanyView(!showCompanyView);
  }

  const ChangeCompany = (org) => {
    dispatch({
      type: SELECTED_COMPANY_SELECTION_SUCCESS,
      payload: { selectedCompany: org },
    });

    removeLocalStorageValue("User_Dashboard_Selected_User");
    removeLocalStorageValue("User_Dashboard_Date_Selection");
    removeLocalStorageValue("Dashboard_User_Selection");
    removeLocalStorageValue("Team_Dashboard_Date_Selection");
    removeLocalStorageValue("Team_Dashboard_Selected_Tab");
    removeLocalStorageValue("Hours_Tracked_User_Selection");
    removeLocalStorageValue("Hours_Tracked_Date_Selection");
    removeLocalStorageValue("Activity_Summary_User_Selection");
    removeLocalStorageValue("Activity_Summary_Date_Selection");
    removeLocalStorageValue("Performance_Report_User_Selection");
    removeLocalStorageValue("Performance_Selected_Tab");
    removeLocalStorageValue("Performance_Report_Month_Selection");
    removeLocalStorageValue("Web_n_Apps_User_Selection");
    removeLocalStorageValue("TeamWebApp_Selected_Tab");
    removeLocalStorageValue("Web_n_Apps_Date_Selection");
    removeLocalStorageValue("Screenshot_User_Selection");
    removeLocalStorageValue("Screenshot_Date_Selection");
    removeLocalStorageValue("Project_Cost_Date_Selection");
    removeLocalStorageValue("Pay_Role_Date_Selection");
    removeLocalStorageValue("projectProgress_Selected_Tab");
    removeLocalStorageValue("Project_n_Progress_Date_Selection");
    removeLocalStorageValue("Project_n_Progress_User_Selection");
    removeLocalStorageValue("Project_n_Progress_Group_Selection");
    removeLocalStorageValue("Active-Timezone");
    removeLocalStorageValue("Attendance_Report_Date_Selection");
    removeLocalStorageValue("Attendance_Report_User_Selection");
    removeLocalStorageValue("Attendance_Report_Group_Selection");
    removeLocalStorageValue("attendanceReport_Selected_Tab");
    removeLocalStorageValue("User_Profitibility_Date_Selection");
    removeLocalStorageValue("User_Profitibility_Selected_Client");
    removeLocalStorageValue("User_Profitibility_Selected_Projects");
    removeLocalStorageValue("Active-Timezone");

    //window.location.reload();
    navigate("/");
    
  }

  const [showProfileView, setShowProfileView] = useState(false);
  const openProfileView = () => {
    setShowProfileView(!showProfileView);
  }
  const [showGuideView, setShowGuideView] = useState(false);
  const openGuideView = () => {
    setShowGuideView(!showGuideView);
  }

  const [timeZoneValue, setTimeZoneValue] = useState("");
  const [offSetValue, setOffSetValue] = useState("");

  useEffect(() => {
    if (timeZoneValue !== "" && offSetValue !== "") {
      setActivesTimezone();
    }
  }, [timeZoneValue, offSetValue])

  const activeTimezone = () => {
    return getActiveTimezone().timeZone;
  }

  const setActivesTimezone = () => {
    let timezoneData = {
      timeZone: timeZoneValue, timeZoneOffset: offSetValue
    };
    setLocalStorageValue("Active-Timezone", JSON.stringify(timezoneData));
    window.location.reload();
  }

  return (
    <>
      <header className={"header bg-white " + leftNavExpand}>
        <Container fluid>
          <Row>
            <Col className='logo d-md-none p-0'>
              <Nav.Item>
                <Nav.Link href="/"><img src={"images/login-black.svg"} alt='' /></Nav.Link>
              </Nav.Item>
            </Col>

            <Col className="dashboard-btn d-none d-xl-block p-0 " >
              <h4 className='page-heading mt-1 d-flex'> {title}
                <Button className='ms-2 bg-transparent box-shadow-btn  ' onClick={openGuideView}>
                  <img src='images/helping-icon.svg' alt="helping-icon" width={17} />
                </Button>
              </h4>
              <p>{description}</p>
              {/* <p className=' mb-0 page-paragraph'>{description}</p> */}
            </Col>

            <Col className='d-flex align-items-center project-prog-set justify-content-end p-0' >
              <div className='header-timezone d-none header-cursor-pointer d-sm-block me-3'>
                <CompanyTimeZone defaultTimeZone={activeTimezone()} setTimeZoneValue={setTimeZoneValue} setOffSetValue={setOffSetValue} />
              </div>

              <div className='john-box company-select d-flex align-items-center me-md-0'>
                <div className='john-drop konext-drop d-flex align-items-center flex-row-reverse w-100'>

                  <DropdownButton id="dropdown-basic-button" title={selectedCompany.name} className="me-md-2 mb-2 mb-sm-0 w-100" disabled={isClientView} onSelect={(e) => { }}>
                    {userCompanies
                      .filter((s) => s.userStatus === 'Active' && s.orgStatus === 'Active')
                      .map((s, index) => (
                        <div className='hover-' key={index}>
                          <div style={{ cursor: 'pointer' }} onClick={() => ChangeCompany(s)}>
                            <p> {s.name} </p>
                          </div>

                          <div style={{ cursor: 'pointer' }} onClick={() => ChangeCompany(s)}>
                            <span>  {s.userRole.length > 1 ? s.userRole[1] : s.userRole[0]} </span>
                          </div>
                        </div>
                      ))}
                    <Dropdown.Item as="li"><Link to="/company-selection">more...</Link></Dropdown.Item>
                  </DropdownButton>

                </div>
                <div className='header-timezone d-block d-sm-none me-3'>
                  <CompanyTimeZone defaultTimeZone={activeTimezone()} setTimeZoneValue={setTimeZoneValue} setOffSetValue={setOffSetValue} />
                </div>
              </div>

              <div className='john-box d-flex align-items-center me-4 pe-2 me-md-0'>
                <div className='john-drop d-flex align-items-center flex-row-reverse'>
                  <span>
                    {
                      profileImgLoading ?
                        <p className="text-center loader profile_img_loader mt-2">
                          <Loading />
                        </p> :
                        userProfile.profileUrl !== null && userProfile.profileUrl !== "" ?
                          userProfile.profileUrl.trim().length !== 0 ?
                            <img src={userProfile.profileUrl} /> :
                            <img src='images/empty_profile.jpg' /> :
                          <img src='images/empty_profile.jpg' />
                    }
                  </span>
                  <ul className='p-0'>
                    <DropdownButton id="dropdown-basic-button" title={userProfile.name} className="me-2 tasks-dd">
                      {/* <Dropdown.Item as="li" disabled={isClientView}><Link onClick={openProfileView}><img src={"images/profile.svg"} alt='' />My Profile</Link></Dropdown.Item> */}

                      <Dropdown.Item as="li">
                        <Link to="/profile">
                          <img src={"images/profile.svg"} alt='' />
                          My Profile
                        </Link>
                      </Dropdown.Item>
                      {/* <Dropdown.Item as="li"><Link onClick={openCompanyView}><img src={"images/profile.svg"} alt='' />Organization Profile</Link></Dropdown.Item> */}
                      <Dropdown.Item as="li">
                        <Link to="/organization">
                          <img src={"images/profile.svg"} alt='' />
                          Organization Profile
                        </Link>
                      </Dropdown.Item>
                      <Dropdown.Item as="li"><Link to="/login" onClick={logOut}><img src={"images/logout.svg"} alt='' />Logout</Link></Dropdown.Item>


                    </DropdownButton>
                  </ul>
                </div>
              </div>
            </Col>
          </Row>
        </Container >
        <AddUserModal />
      </header >
      {showProfileView && <ProfileView show={showProfileView} handleClose={openProfileView} onSubmit={openProfileView} orgId={selectedCompany.id} profileImgLoading={setProfileImgLoading} />}

      {showCompanyView && <CompanyProfile show={showCompanyView} handleClose={openCompanyView} onSubmit={openCompanyView} companySettingsLoading={companySettingsLoading} companyRecord={selectedCompany} />}

      {showGuideView && <GuideView show={showGuideView} handleClose={openGuideView} pageTitle={title} fullUrl={fullUrl} contentUrl={contentUrl} />}

      <Modal className='adduserpopap UpdatePopup' show={showUpdatePanel} onHide={() => { setShowUpdatePanel(!showUpdatePanel) }} backdrop="static" keyboard={false}>
        <Modal.Header className='flex-column'>
          <h4> update Available </h4>
          <p> Please update to get latest version </p>
        </Modal.Header>

        <div>
          <Modal.Body>
            <form className="App" autoComplete="off">
              <Button onClick={() => { window.location.reload(true); setShowUpdatePanel(!showUpdatePanel); }}> Update</Button>
            </form>
          </Modal.Body>
        </div >
      </Modal>

    </>
  )
}
const mapStateToProps = state => ({
  userProfile: state.UserReducer.userProfile,
  selectedCompany: state.UserReducer.selectedCompany,
  userCompanies: state.UserReducer.userCompanies,
  reactLink: state.SupportReducer.reactLink,
  leftNavExpand: state.SupportReducer.leftNavExpand,
  isClientView: state.SupportReducer.isClientView,
});

export default connect(mapStateToProps)(Header);
